/**
 * React hook that is used to mark the block wrapper element.
 * It provides all the necessary props like the class name.
 *
 * @see https://developer.wordpress.org/block-editor/reference-guides/packages/packages-block-editor/#useblockprops
 */
import { useState }   from '@wordpress/element';
import { createRoot } from 'react-dom/client';


/**
 * Lets webpack process CSS, SASS or SCSS files referenced in JavaScript files.
 * Those files can contain any CSS code that gets applied to the editor.
 *
 * @see https://www.npmjs.com/package/@wordpress/scripts#using-css
 */
import './editor.scss';
import Constants      from '../constants';
import Events         from '../components/Events.component';

/**
 * The edit function describes the structure of your block in the context of the
 * editor. This represents what the editor will render when the block is used.
 *
 * @see https://developer.wordpress.org/block-editor/reference-guides/block-api/block-edit-save/#edit
 *
 * @return {Element} Element to render.
 */
export default function View() {
    const [loading, setLoading]     = useState(false);
    const [error, setError]         = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    let entityCode                  = '';
    let page                        = '';
    let pageSize                    = '';
    let lang                        = 'ar';

    // Select all instances of your custom block.
    const blockElements = document.querySelectorAll('.bseit-events-block');

    blockElements.forEach((block) => {
        // Access the block's attributes using the data-* attributes.
        entityCode = block.getAttribute('data-entity-code');
        page       = block.getAttribute('data-page');
        pageSize   = block.getAttribute('data-page-size');
        lang       = block.getAttribute('data-lang');
    });

    const tabs = [
        {
            id: 'tab-1',
            name: 'futureEvents',
            title: bseitEditorVars.phrases.current_events,
            tabComponent: <Events
                eventType="current"
                entityCode={entityCode}
                page={page}
                pageSize={pageSize}
                lang={lang}
            />,
        },
        {
            id: 'tab-2',
            name: 'pastEvents',
            title: bseitEditorVars.phrases.past_events,
            tabComponent: <Events
                eventType="past"
                entityCode={entityCode}
                page={page}
                pageSize={pageSize}
                lang={lang}
            />,
        },
    ];

    const tabButtonActiveClass = 'border-primary text-primary';
    const tabButtonNormalClass = 'border-white text-darkGray';

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    return (<>
        <div className="gap-64 mt-12 sm:flex">
            <div className="flex justify-center space-x-4 border-b border-lightGray">
                {tabs.map((tab, index) => (<button
                    onClick={() => handleTabClick(index)}
                    type="button"
                    id={tab.id}
                    className={`${activeTab === index ? tabButtonActiveClass : tabButtonNormalClass} transition px-10 py-2 text-lg font-bold border-b-2 min-w-52 hover:text-primary hover:border-primary hover:font-bold focus:text-primary active:text-primary active:border-primary tab-button`}
                    aria-label="Button"
                >
                    {tab.title}
                </button>))}
            </div>
        </div>
        <div className="pt-10">
            {tabs.map((tab, index) => (<div
                key={tab.id}
                id={`content-${tab.id}`}
                className={`${activeTab === index ? 'block' : 'hidden'}`}
            >
                {tab.tabComponent}
            </div>))}
        </div>
    </>);
}

const root = createRoot(document.getElementById('root'));
root.render(<View/>);
