const Constants = {
	TEXT_DOMAIN: "blackstoneeit-wp-plugin-blocks",
	APIS: {
		ROOT_URL: {
			DEV: "https://hheo-gateway-dev.redfield-2067e6a5.uaenorth.azurecontainerapps.io",
			QC: "https://hheo-gateway-qc.wonderfulplant-e6007fd6.uaenorth.azurecontainerapps.io",
			// STG: "https://hheo-gateway-stg.politewave-7a937208.uaenorth.azurecontainerapps.io", // Original
			STG: "https://hheo-gateway-stg.politewave-7a937208.uaenorth.azurecontainerapps.io",
			// PROD: "https://hheo-gateway-prod.redcoast-d076bdab.uaenorth.azurecontainerapps.io", // Original
			PROD: "https://hheo-gateway-stg.politewave-7a937208.uaenorth.azurecontainerapps.io",
		},
		JOBS: {
			LIST: "/CareersAndVolunteers/api/public/wordpress/careers/jobs/list",
			DETAILS:
				"/CareersAndVolunteers/api/public/wordpress/careers/jobs/details",
			APPLY: "/jobs",
			WORK_ENVIRONMENTS:
				"/CareersAndVolunteers/api/public/careers/lookups/work-environment",
			TYPES: "/CareersAndVolunteers/api/public/careers/lookups/job-type",
		},
		VOLUNTEERS: {
			LIST: "/CareersAndVolunteers/api/public/wordpress/volunteers/list",
			DETAILS:
				"/CareersAndVolunteers/api/public/wordpress/volunteers/details",
			APPLY: "/CareersAndVolunteers/api/volunteers/apply",
		},
	},
	BOOLEAN_CHOICE: [
		{ label: "Yes", value: true },
		{ label: "No", value: false },
	],
	TEMPLATE_STYLE_TYPES: [
		{ label: "Style Template One", value: "styleOne" },
		{ label: "Style Template Two", value: "styleTwo" },
		{ label: "Style Template Three", value: "styleThree" },
	],
	BUTTON_STYLE_TYPE_SELECT_OPTIONS: [
		{ label: "Primary", value: "primary" },
		{ label: "Dark Primary", value: "darkPrimary" },
		{ label: "Light Primary", value: "lightPrimary" },
		{ label: "Secondary", value: "secondary" },
		{ label: "Dark Gray", value: "darkGray" },
		{ label: "Light Gray", value: "lightGray" },
		{ label: "Semi Gray", value: "semiGray" },
		{ label: "Black", value: "black" },
		{ label: "Black&White", value: "blackWhite" },
		{ label: "Transparent", value: "transparent" },
		{ label: "Link", value: "link" },
	],
	BUTTON_STYLE_CLASSES_SELECT_OPTIONS: [
		{
			label: "primary",
			value:
				"inline-block py-2 mt-10 text-white transition-all border hover:shadow-lg bg-primary border-primary hover:bg-white hover:text-primary hover:border hover:border-primary lg:py-3 px-9 no-underline",
		},
		{
			label: "darkPrimary",
			value:
				"inline-block py-2 mt-10 text-white transition-all border hover:shadow-lg bg-darkPrimary border-darkPrimary hover:bg-white hover:text-darkPrimary hover:border hover:border-darkPrimary lg:py-3 px-9 no-underline",
		},
		{
			label: "lightPrimary",
			value:
				"inline-block py-2 mt-10 text-white transition-all border hover:shadow-lg bg-lightPrimary border-lightPrimary hover:bg-white hover:text-lightPrimary hover:border hover:border-lightPrimary lg:py-3 px-9 no-underline",
		},
		{
			label: "secondary",
			value:
				"inline-block py-2 mt-10 text-white transition-all border hover:shadow-lg bg-secondary border-secondary hover:bg-white hover:text-secondary hover:border hover:border-secondary lg:py-3 px-9 no-underline",
		},
		{
			label: "darkGray",
			value:
				"inline-block py-2 mt-10 text-white transition-all border hover:shadow-lg bg-darkGray border-darkGray hover:bg-white hover:text-darkGray hover:border hover:border-darkGray lg:py-3 px-9 no-underline",
		},
		{
			label: "lightGray",
			value:
				"inline-block py-2 mt-10 text-body transition-all border hover:shadow-lg bg-lightGray border-lightGray hover:bg-white hover:text-body hover:border hover:border-lightGray lg:py-3 px-9 no-underline",
		},
		{
			label: "semiGray",
			value:
				"inline-block py-2 mt-10 text-white transition-all border hover:shadow-lg bg-semiGray border-semiGray hover:bg-white hover:text-body hover:border hover:border-semiGray lg:py-3 px-9 no-underline",
		},
		{
			label: "black",
			value:
				"inline-block py-2 mt-10 text-white transition-all border hover:shadow-lg bg-black border-black hover:bg-white hover:text-black hover:border hover:border-black lg:py-3 px-9 no-underline",
		},
		{
			label: "blackWhite",
			value:
				"inline-block py-2 mt-10 text-black transition-all border hover:shadow-lg bg-white border-black hover:bg-black hover:text-white hover:border hover:border-black lg:py-3 px-9 no-underline",
		},
		{
			label: "transparent",
			value:
				"inline-block py-2 mt-10 text-primary transition-all border hover:shadow-lg bg-transparent border-transparent hover:bg-primary hover:text-white hover:border hover:border-primary lg:py-3 px-9 no-underline",
		},
		{
			label: "link",
			value:
				"inline-block mt-10 text-primary transition-all border-none hover:underline bg-transparent no-underline",
		},
	],
	THEME_COLORS_SELECT_OPTIONS: [
		{ label: "Primary", value: "primary" },
		{ label: "Dark Primary", value: "darkPrimary" },
		{ label: "Light Primary", value: "lightPrimary" },
		{ label: "Semi Primary", value: "semiPrimary" },
		{ label: "Secondary", value: "secondary" },
		{ label: "Dark Green", value: "darkGreen" },
		{ label: "Dark Gray", value: "darkGray" },
		{ label: "Light Gray", value: "lightGray" },
		{ label: "Semi Gray", value: "semiGray" },
		{ label: "Body", value: "body" },
		{ label: "Black", value: "black" },
		{ label: "White", value: "white" },
		{ label: "Transparent", value: "transparent" },
		{ label: "Custom Color", value: "customColor" },
	],
	CONTENT_DIRECTION_SELECT_OPTIONS: [
		{ label: "Horizontal", value: "row" },
		{ label: "Vertical", value: "col" },
	],
	CONTENT_ALIGNMENT_SELECT_OPTIONS: [
		{ label: "Left", value: "start" },
		{ label: "Right", value: "end" },
		{ label: "Center", value: "center" },
	],
	BORDER_WIDTH_SELECT_OPTIONS: [
		{ label: "Small", value: "border" },
		{ label: "Medium", value: "border-2" },
		{ label: "Large", value: "border-4" },
		{ label: "XLarge", value: "border-8" },
		{ label: "No Border", value: "border-0" },
	],
	RADIUS_SELECT_OPTIONS: [
		{ label: "Base", value: "rounded" },
		{ label: "Small", value: "rounded-sm" },
		{ label: "Medium", value: "rounded-md" },
		{ label: "Large", value: "rounded-lg" },
		{ label: "X Large", value: "rounded-xl" },
		{ label: "2X Large", value: "rounded-2xl" },
		{ label: "3X Large", value: "rounded-2xl" },
		{ label: "Full Rounded", value: "rounded-full" },
		{ label: "No Radius", value: "rounded-none" },
	],
	MARGIN_TOP_SELECT_OPTIONS: [
		{ label: "Small", value: "mt-5" },
		{ label: "Medium", value: "mt-8" },
		{ label: "Large", value: "mt-10" },
		{ label: "XLarge", value: "mt-24" },
		{ label: "No Margin", value: "mt-0" },
	],
	MARGIN_BOTTOM_SELECT_OPTIONS: [
		{ label: "Small", value: "mb-5" },
		{ label: "Medium", value: "mb-8" },
		{ label: "Large", value: "mb-10" },
		{ label: "XLarge", value: "mb-24" },
		{ label: "No Margin", value: "mb-0" },
	],
	FONT_SIZE_SELECT_OPTIONS: [
		{ label: "Small", value: "text-sm" },
		{ label: "Base", value: "text-base" },
		{ label: "Medium", value: "text-md" },
		{ label: "X Large", value: "text-xl" },
		{ label: "2X Large", value: "text-2xl" },
		{ label: "3X Large", value: "text-3xl" },
		{ label: "4X Large", value: "text-4xl" },
		{ label: "5X Large", value: "text-5xl" },
		{ label: "hidden", value: "text-[0px]" },
	],
	BSEIT_BLOCKS: [
		"bseit-wp-blocks/blogs-single",
		"bseit-wp-blocks/blogs-archive",
		"bseit-wp-blocks/header-one",
		"bseit-wp-blocks/header-two",
		"bseit-wp-blocks/header-three",
		"bseit-wp-blocks/footer-one",
		"bseit-wp-blocks/footer-two",
		"bseit-wp-blocks/footer-three",
		"bseit-wp-blocks/partners-one",
		"bseit-wp-blocks/partners-two",
		"bseit-wp-blocks/partners-three",
		"bseit-wp-blocks/hero-two",
		"bseit-wp-blocks/why-us-two",
		"bseit-wp-blocks/media-text",
		"bseit-wp-blocks/news-subscription",
		"bseit-wp-blocks/icon-text",
		"bseit-wp-blocks/events-one",
		"bseit-wp-blocks/events-two",
		"bseit-wp-blocks/join-team-two",
		"bseit-wp-blocks/customer-review",
		"bseit-wp-blocks/achievements-tabs",
		"bseit-wp-blocks/hero-inner-page",
		"bseit-wp-blocks/e-library",
		"bseit-wp-blocks/events",
		"bseit-wp-blocks/our-mission-slider",
		"bseit-wp-blocks/contact-us",
		"bseit-wp-blocks/gallery",
		"bseit-wp-blocks/no-data",
		"bseit-wp-blocks/slider",
		"bseit-wp-blocks/news-list",
		"bseit-wp-blocks/news-one",
		"bseit-wp-blocks/faqs-one",
		"bseit-wp-blocks/t3-vertical-slider",
		"bseit-wp-blocks/t3-vertical-slide-item"
	],
};
export default Constants;
