import { __ } from "@wordpress/i18n";
import Constants from "../constants";

/**
 * Pagination component.
 *
 * @param {Object} props - Component props.
 * @param {number} props.currentPage - The current page number.
 * @param {number} props.totalPages - The total number of pages.
 * @param {Function} props.onPageChange - Function to call when a page is changed.
 * @returns {JSX.Element} The pagination component.
 */
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
	// Generate page numbers
	const buttonActiveClasses = "bg-primary text-white";
	const buttonInactiveClasses = "bg-white text-black";
	const buttonClasses =
		"transition cursor-pointer relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 rounded-[82px] focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary hover:text-white";
	const buttonDisabledClasses = "opacity-50 cursor-not-allowed";

	/**
	 * Handles a page click event by calling the onPageChange function with the selected page.
	 *
	 * @param {number} page - The page number that was clicked.
	 */
	const handlePageClick = (page) => {
		onPageChange(page);
	};

	/**
	 * Generates an array of page links for pagination.
	 *
	 * @returns {Array} An array of page links, where each link is a button element.
	 */
	const generatePageLinks = () => {
		let pageLinks = [];

		if (totalPages <= 5) {
			for (let page = 1; page <= totalPages; page++) {
				pageLinks.push(
					<button
						type="button"
						key={page}
						onClick={() => handlePageClick(page)}
						aria-current={page === currentPage ? "true" : "false"}
						className={
							buttonClasses +
							(page === currentPage
								? ` ${buttonActiveClasses}`
								: ` ${buttonInactiveClasses}`)
						}
						aria-label="Button"
					>
						{page}
					</button>,
				);
			}
		} else {
			if (currentPage > 3) {
				pageLinks.push(
					<button
						type="button"
						key={1}
						onClick={() => handlePageClick(1)}
						className={buttonClasses}
						aria-label="Button"
					>
						1
					</button>,
					<span
						key="ellipsis-start"
						className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-[82px] pagination-ellipsis ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
					>
						...
					</span>,
				);
			}

			for (
				let page = Math.max(1, currentPage - 2);
				page <= Math.min(totalPages, currentPage + 2);
				page++
			) {
				pageLinks.push(
					<button
						type="button"
						key={page}
						onClick={() => handlePageClick(page)}
						isPrimary={page === currentPage}
						className={
							buttonClasses +
							(page === currentPage
								? ` ${buttonActiveClasses}`
								: ` ${buttonInactiveClasses}`)
						}
						aria-label="Button"
					>
						{page}
					</button>,
				);
			}

			if (currentPage < totalPages - 2) {
				pageLinks.push(
					<span key="ellipsis-end" className="pagination-ellipsis">
						...
					</span>,
					<button
						type="button"
						key={totalPages}
						onClick={() => handlePageClick(totalPages)}
						className={buttonClasses}
						aria-label="Button"
					>
						{totalPages}
					</button>,
				);
			}
		}

		return pageLinks;
	};

	return (
		<div className="flex justify-center mt-8 pagination-container">
			<div className="pagination-buttons">
				<nav
					className="inline-flex gap-4 space-x-px isolate"
					aria-label="Pagination"
				>
					<button
						type="button"
						onClick={() => onPageChange(currentPage - 1)}
						disabled={currentPage === 1}
						className={
							buttonClasses +
							(currentPage === 1 ? ` ${buttonDisabledClasses}` : "")
						}
						aria-label="Button"
					>
						<span className="sr-only">
							{__("Previous", Constants.TEXT_DOMAIN)}
						</span>
						<svg
							className="w-5 h-5 ltr:-scale-x-100"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fillRule="evenodd"
								d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
								clipRule="evenodd"
							/>
						</svg>
					</button>
					{generatePageLinks()}
					<button
						type="button"
						onClick={() => onPageChange(currentPage + 1)}
						disabled={currentPage === totalPages}
						className={
							buttonClasses +
							(currentPage === totalPages ? ` ${buttonDisabledClasses}` : "")
						}
						aria-label="Button"
					>
						<span className="sr-only">{__("Next", Constants.TEXT_DOMAIN)}</span>
						<svg
							className="w-5 h-5 ltr:-scale-x-100"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fillRule="evenodd"
								d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
								clipRule="evenodd"
							/>
						</svg>
					</button>
				</nav>
			</div>
			{/* <div className="pagination-info">
				{totalPages > 1 && (
					<span>
						{sprintf(
							__("Page %d of %d", Constants.TEXT_DOMAIN),
							currentPage,
							totalPages,
						)}
					</span>
				)}
			</div> */}
		</div>
	);
};

export default Pagination;
