import apiFetch from '@wordpress/api-fetch';

let token      = null;
let nonce      = null;
let nonceValid = false;
let username   = 'bseithheojwtadmin';
let password   = 'JhiO qWPm pVQm AsQj CM3n lK3Z';


const fetchToken = async () => {
    try {
        const response = await apiFetch({
            path: '/bseitwp/v1/jwt/generate',
            method: 'POST',
            headers: {
                'X-BSEITWP-AUTH-TYPE': 'key',
            },
            data: {
                username: username,
                password: password,
            },
        });
        token          = response.data.token;
    } catch (error) {
        console.error('Error fetching token:', error);
        throw error;
    }
};

const fetchValidateToken = async () => {
    try {
        const response = await apiFetch({
            path: '/bseitwp/v1/jwt/validate',
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (typeof response.status !== 'undefined' || response.status !== 'success') {
            await fetchToken();
        }

    } catch (error) {
        console.error('Error fetching validate token:', error);
        throw error;
    }
};

const fetchValidateNonce = async () => {
    try {
        const response = await apiFetch({
            path: '/bseitwp/v1/nonce/validate',
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'X-BSEITWP-Nonce': nonce,
            },
        });

        if (typeof response.status !== 'undefined' || response.status !== 'success') {
            await fetchNonce();
        }

        nonce      = response.nonce;
        nonceValid = true;
    } catch (error) {
        console.error('Error fetching nonce:', error);
        throw error;
    }
};

const fetchNonce = async () => {
    if (!token) {
        await fetchToken();
    }

    try {
        const response = await apiFetch({
            path: '/bseitwp/v1/nonce/create',
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        nonce          = response.data.nonce;
    } catch (error) {
        console.error('Error fetching nonce:', error);
        throw error;
    }
};

export const checkNonce = async (nonce) => {
    if (token) {
        await fetchValidateToken();
    } else {
        await fetchToken();
    }

    await fetchValidateNonce(nonce);
};

export const fetchData = async (endpoint, method = 'GET') => {

    if (!nonce) {
        await fetchNonce();
    }

    try {
        return await apiFetch({
            path: endpoint,
            method: method,
            headers: {
                'X-BSEITWP-Nonce': nonce,
            },
        });
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
