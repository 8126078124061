/**
 * React hook that is used to mark the block wrapper element.
 * It provides all the necessary props like the class name.
 *
 * @see https://developer.wordpress.org/block-editor/reference-guides/packages/packages-block-editor/#useblockprops
 */
import { useEffect, useState, useCallback } from '@wordpress/element';

/**
 * Lets webpack process CSS, SASS or SCSS files referenced in JavaScript files.
 * Those files can contain any CSS code that gets applied to the editor.
 *
 * @see https://www.npmjs.com/package/@wordpress/scripts#using-css
 */
import { fetchData }                        from '../api';
import { Spinner }                          from '@wordpress/components';
import Pagination                           from './Pagination.component';

import Constants from '../constants';
import { __ }    from '@wordpress/i18n';

/**
 * The edit function describes the structure of your block in the context of the
 * editor. This represents what the editor will render when the block is used.
 *
 * @see https://developer.wordpress.org/block-editor/reference-guides/block-api/block-edit-save/#edit
 *
 * @return {Element} Element to render.
 */
export default function Events({ eventType, entityCode, page, pageSize, lang, phrases}) {

    const [libraries, setLibraries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    let environment = 'https://hheo-gateway-dev.redfield-2067e6a5.uaenorth.azurecontainerapps.io';

    if (bseitEditorVars.envType === 'dev') {
        environment = 'https://hheo-gateway-dev.redfield-2067e6a5.uaenorth.azurecontainerapps.io';
    } else if (bseitEditorVars.envType === 'stg') {
        environment = 'https://hheo-gateway-stg.politewave-7a937208.uaenorth.azurecontainerapps.io';
    } else if (bseitEditorVars.envType === 'qc') {
        environment = 'https://hheo-gateway-qc.wonderfulplant-e6007fd6.uaenorth.azurecontainerapps.io';
    }


    useEffect(() => {
        const fetchEvents = async (page = 1) => {
            setLoading(true); // Start loading state
            setError(null);   // Clear any previous errors

            try {
                // console.log(`Fetching events for page ${page}`);

                // Make the API request using the provided URL
                const response = await fetch(`${environment}/EventService/api/public/events/${entityCode}?page=${page}&pageSize=${pageSize}&eventType=${eventType}`, {
                    method: 'GET',  // GET method for fetching data
                    headers: {
                        'Accept-Language': lang,  // Use the defined language variable
                    },
                });
                // console.log('API Response:', response);

                // Check if the response is OK (status code 2xx)
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                // Parse the response data
                const data = await response.json();

                // Check if the data contains the necessary attributes before setting state
                if (data && data.data && data.pagination) {
                    setLibraries(data.data);
                    setTotalPages(data.pagination.totalPages);
                } else {
                    throw new Error('Unexpected API response structure');
                }
            } catch (error) {
                console.error('Error fetching events:', error);
                setError(error.message || 'An unexpected error occurred');  // Set the error state
            } finally {
                setLoading(false);  // Stop loading state
            }
        };

        fetchEvents(currentPage);
    }, [
        entityCode,
        pageSize,
        eventType,
        environment,
        currentPage,
    ]);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (<>
        <div className="flex flex-col">
            {loading && (<div className="flex items-center justify-center w-full">
                <Spinner
                    style={{
                        height: 'calc(2px * 20)',
                        width: 'calc(2px * 20)',
                    }}
                />
            </div>)}

            {error && <p>{error.message}</p>}

            {!loading && libraries.map((item, index) => (<div
                key={index}
                className="flex flex-col mb-24 md:justify-between group md:odd:flex-row-reverse md:flex-row"
            >

                <div className="w-6/12 overflow-hidden rounded-lg group-odd:ms-16 group-even:me-16 h-[360px]">
                    <img
                        src={item.imgUrl !== '' ? item.imgUrl : 'https://placehold.co/130x60?text=Image1+711x360'}
                        className="object-cover w-full h-full mb-5 lg:mb-0"
                        alt=""
                        loading="lazy"
                    />
                </div>
                <div className="w-6/12 pb-12 group-odd:pe-12 group-even:ps-12 text-start">
                    <h2 className="text-2xl font-bold text-body"
                        dangerouslySetInnerHTML={{ __html: item.title }}>
                    </h2>
                    <p className="py-4 pb-8 lg:py-10"
                       dangerouslySetInnerHTML={{ __html: item.description }}
                    >
                    </p>
                    {item.btnDirection !== '' && (<a
                            target="_blank"
                            href={item.btnDirection}
                            className="w-48 px-12 py-3 my-5 text-white transition-all border border-secondary bg-secondary lg:mt-0 hover:bg-white hover:text-secondary hover:border h-14 rounded-xl"
                        >
                            {phrases.learn_more}
                        </a>)}
                </div>
            </div>))}
        </div>
        {!loading && totalPages > 1 && (<Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
        />)}
    </>);
}
